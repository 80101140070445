import { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from './state/userState';
import { formState } from './state/formState';
import { adminState } from './state/adminState';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { jwtDecode } from 'jwt-decode'; // Fixed import
import './App.css';

// Lazy-loaded components
const HeaderComponent = lazy(() => import('./components/HeaderComponent'));
const LoginForm = lazy(() => import('./components/LoginForm'));
const FormTabs = lazy(() => import('./components/FormTabs'));
const SubForms = lazy(() => import('./components/UserSubForms'));
// Add RegisterForm if you have it
const SignInForm = lazy(() => import('./components/SignInForm'));
const ResetPasswordForm = lazy(() => import('./components/ResetPasswordForm'));
//admin component
const AdminDashboard = lazy(() => import('./admin/components/AdminDashboard'));
const LoginAdminForm = lazy(() => import('./admin/components/LoginAdmin'));
const UserSubFormDetails = lazy(() => import('./components/UserSubFormDetails'));

const theme = createTheme({ direction: 'rtl' });

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  const [user, setUser] = useRecoilState(userState);
  const [admin, setAdmin] = useRecoilState(adminState);
  const [, setForm] = useRecoilState(formState);
  const handleToken = (token) => {
    const decodedToken = jwtDecode(token);
    setUser({
      userId: decodedToken?.userId,
      useremail: decodedToken?.email,
      token,
    });
  };
  //adminuser
  const handleAdminToken = (token) => {
    const decodedToken = jwtDecode(token);
    setAdmin({
      ...decodedToken,
    });
  };

  useEffect(() => {
    const token = sessionStorage.getItem('authToken');
    if (token) {
      handleToken(token);
    }
    const admintoken = sessionStorage.getItem('authAdminToken');
    if (admintoken) {
      handleAdminToken(admintoken);
    }
  }, []);

  const handleLoginSuccess = (token) => {
    if (!token) {
      return;
    }
    handleToken(token);
    sessionStorage.setItem('authToken', token);
  };

  const handleAdminLoginSuccess = (token) => {
    if (!token) {
      return;
    }
    handleAdminToken(token);
    sessionStorage.setItem('authAdminToken', token);
  };
  const handleUserLogout = () => {
    sessionStorage.clear();
    // sessionStorage.removeItem('authToken');
    setUser({ userId: null, useremail: null, token: null });
    setForm({});
    window.location.href = '/';
  };
  const handleAdminLogout = () => {
    sessionStorage.clear();
    //sessionStorage.removeItem('authAdminToken');
    setAdmin({});
  };
  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Router>
          <div className="App">
            <Suspense fallback={<div>Loading...</div>}>
              {/* hide header if admin is logged in */}
              {!admin.isAdmin && <HeaderComponent onUserLogout={handleUserLogout} />}

              <Routes>
                {/* Redirect to FormTabs if user is logged in, else show LoginForm */}
                <Route
                  path="/"
                  element={
                    user.token ? (
                      <Navigate replace to="/formtabs" />
                    ) : (
                      <Navigate replace to="/login" />
                    )
                  }
                />
                <Route
                  path="/"
                  element={
                    user.token ? (
                      <Navigate replace to="/subforms" />
                    ) : (
                      <Navigate replace to="/login" />
                    )
                  }
                />
                <Route
                  path="/subformdetails/:subformid/:formid/:designid"
                  element={user.token ? <UserSubFormDetails /> : <Navigate replace to="/login" />}
                />
                <Route
                  path="/login"
                  element={
                    !user.token ? (
                      <LoginForm onLoginSuccess={handleLoginSuccess} />
                    ) : (
                      <Navigate replace to="/formtabs" />
                    )
                  }
                />
                <Route
                  path="/formtabs/:designid"
                  element={user.token ? <FormTabs /> : <Navigate replace to="/login" />}
                />

                <Route
                  path="/formtabs"
                  element={user.token ? <FormTabs /> : <Navigate replace to="/login" />}
                />
                <Route
                  path="/subforms"
                  element={user.token ? <SubForms /> : <Navigate replace to="/login" />}
                />
                {/* If you have a RegisterForm, add a Route for it here */}
                <Route path="/register" element={<SignInForm />} />
                <Route path="/reset-password/:resetetoken" element={<ResetPasswordForm />} />
                
                
                {/* Add a Route for AdminDashboard */}
                <Route
                  path="/admin"
                  element={
                    admin.isAdmin ? (
                      <Navigate replace to="/admin/dashboard" />
                    ) : (
                      <LoginAdminForm onLoginSuccess={handleAdminLoginSuccess} />
                    )
                  }
                />
                <Route
                  path="/admin/dashboard"
                  element={
                    admin.isAdmin ? (
                      <AdminDashboard
                        onLogoutAdmin={handleAdminLogout}
                        onLoginAsUser={handleLoginSuccess}
                      />
                    ) : (
                      <Navigate replace to="/admin" />
                    )
                  }
                />
              </Routes>
            </Suspense>
          </div>
        </Router>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
